(function($) {

    $(function() {
        // PDF Link
        $('.field-body a').each(function(i, v) {

            var $el = $(v);

            if ($el.attr('href').endsWith('.pdf')) {
                $el.addClass('pdf-link');
            }

        });
    });



    Drupal.behaviors.app = {
        attach: function(context, settings) {}
    };

})(jQuery);
